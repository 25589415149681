@use 'src/styles/vars';
.red {
    opacity: 1 !important;
    transform: translateX(0%) !important;
    transition: all 1s ease-in-out;
}
.homepage {
    display: flex;
    position: relative;
    padding: 40px 40px 40px 40px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow: hidden;
    @include vars.border-top;
      &::after {
        content: "";
        background: url("../../assets/img/map.jpeg");
        background-size: cover;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
        &__section {
            border-top: solid 1px #27596F;
        }
        &__up {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            justify-content: space-around;
        }
        &__down {
            display: flex;
            justify-content: space-around;
        }
        &__image {
            width: 30%;
            display: flex;
            align-items: center;
     
        }
        &__content {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 40px;
        }
        &__picture {
            width: 80%;
            height: auto;
            border-radius: 10px;
        }
        &__title {
            margin-top: 30px;
            font-size: max(70px, 8vw);
            font-family: vars.$calliFont;
            color: vars.$mainColor;
        }
        &__subtitle {
            font-family: vars.$mainFont;
            color: vars.$mainColor;
            font-size: max(25px, 1.8vw);
            font-style: italic;
            width: 80%;
            margin-bottom: 30px;
            margin-top: 30px;
       

        }
        &__text {
            font-family: vars.$mainFont;
            width: 80%;
            font-size: max(20px, 1.5vw);
            line-height: 1.3;
            opacity: 0;
            &--right {
                transform: translateX(100%) !important;
            }
            &--left {
                transform: translateX(-100%) !important;
            }
            &--show {
                opacity: 1 !important;
                transform: translateX(0%) !important;
                transition: all 1s ease-in-out;
            }
        }
}

@media screen and (max-width: 768px) {
    .homepage {
        padding: 20px 0px;
        justify-content: center;
        &__content {
            width: 95%;
        }
        &__title {
            margin-top: 20px;
        }
        &__subtitle {
            margin-bottom: 20px;
            margin-top: 20px;
        }
        &__image {
            width: 95%;
            justify-content: center;
        }
        &__up {
            flex-direction: column;
            align-items: center;
            padding-top: 0px;
        }
        &__down {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }
    }
}

