.leaflet-container {
    height: 40vh;
    width: 100%;
    margin: 0px auto;
    margin-bottom: 30px;
   // padding-top: 10px;
}

.leaflet {
    height: 300px;
}