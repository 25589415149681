@use 'src/styles/vars';

.availability {
  display: flex;
  flex-direction: column;
  @include vars.border-top;
  align-items: center;
  //padding: 40px;
  margin-bottom: 20px;
  padding-top: 40px;
  min-height: 80vh;
    &::after {
        content: "";
        background: url("../../assets/img/map.jpeg");
        background-size: cover;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
    &__title {
      font-family: vars.$calliFont;
      color: vars.$mainColor;
      font-size: max(50px, 5vw);
    }
    &__up {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
    }
    &__left {
      width: 50%;
    }
    &__right {
      padding-top: 20px;
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      transform: translateX(100%) !important;
      &--show {
        opacity: 1 !important;
        transform: translateX(0%) !important;
        transition: all 1s ease-in-out;
      }
      &--content {
        font-family: vars.$mainFont;
        color: vars.$mainColor;
        font-size: max(15px, 1.4vw);
        font-style: italic;
      }
    }
}

.logo {
  display: flex;
  align-items: center;
  &__container {
    margin-top: 40px;
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
  &__link {
    color: black;
    text-decoration: none;
  }
  &__picture {
    height: 40px;
  }

}

@media screen and (max-width: 768px) {
  .availability {
    padding-top: 20px;
    &__up{
      width: 90%;
      flex-direction: column;
      padding-bottom: 20px;
    }
    &__right {
      width: 100%;
    }
  }
  .fc {
    width: 90%;
  }
}