body {
  margin: 0;
  font-family: 'opensans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@font-face {
  font-family: "birds";
  src: url('../assets/fonts/birds_of_paradise.woff2');
  font-display: swap;
}


@font-face {
  font-family: "opensans";
  src: url('../assets/fonts/OpenSans-Regular.woff2');
  font-display: swap;
}