@use 'src/styles/vars';

.navigation {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #fff;
    color: vars.$mainColor;
        &__title {
            text-decoration: none;
            width: 140px;
            font-weight: bold;
            letter-spacing: 0.1em;
            color: vars.$mainColor;
            font-size: 2.6em;
            margin-left: 1rem;
            font-family: vars.$calliFont;
            &--first {
                text-align: left;
            }
            &--second {
                text-align: right;
            }
        }

  }

.navigation-menu {
    margin-left: auto;
    height: 100%;
    display: flex;
    align-items: center;
        &__list {
            display: flex;
            padding: 0;
            height: 100%;
        }
        &__item {
            list-style-type: none;
            margin: 0 1rem;
            height: 100%;
            display: flex;
            align-items: center;        
        }
  }

  .nav__item {
    text-decoration: none;
    display: block;
    width: 100%;
    color: vars.$mainColor;
    font-size: max(15px, 1.3vw);
    // font-size: 2vw;
    font-family: vars.$mainFont;
    &::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background: vars.$mainColor;
        transition: width .3s;
      }
      &:hover::after {
        width: 100%;
      }
    &--active::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: vars.$mainColor;
    }
  }

  .hamburger {
    // removes default border on button element
    border: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: vars.$mainColor;
    cursor: pointer;
    transition: height 0.2s ease-in-out, width 0.2s ease-in-out;
  // positions the icon to the right and center aligns it vertically
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
        &__icon {
            height: 70%;
            text-align: center;
        }
        &:hover {
            height: 45px;
            width: 45px;
        }
  }
.contact-section {
    background-color: vars.$mainColor;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
        &__logo {
            height: 40px;
            width: 40px;
        }
        &__text {
            color: white;
            margin-left: 0.5em;
        }
        &__place {
            display: flex;
            align-items: center;
        }
}

#navigation__contact-section {
    width: 250px;
    display: block;
    margin-right: 0;
}

@media screen and (max-width: 995px) {
    #navigation__contact-section {
        width: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    .hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .navigation-menu {
        &__item {
            height: auto;
            text-align: center;
            margin: 0;
        }
        &__list {
            display: none;
            position: absolute;
            top: 100px;
            left: 0;
            flex-direction: column;
            width: 100%;
            height: auto;
            background-color: white;
            border-top: 1px solid black;
        }
        &--expanded ul{
            display: block;
            z-index: 1100;
            opacity: 0.9;
        }
    }
    .nav__item {
        width: 100%;
        padding: 1.5rem 0;
        &::after {
        
            margin: auto;
        }
        &:hover::after {
            width: 20%;
            margin: auto;
          }
        &--active::after {
            width: 20%;
            margin: auto;
        }
    }
    #navigation__contact-section {
        width: 100%;
    }

  }


