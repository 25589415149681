@use 'src/styles/vars';
.sliderimg {
    max-width: 100%;
    height: auto;
    min-width: 100%;
}

.alice-carousel__dots {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    bottom: 20px;
}

@media screen and (max-width: 768px) {
    .alice-carousel__dots {
        bottom: 10px;
    }
}