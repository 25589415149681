@use 'src/styles/vars';

.proximite {
    position: relative;
    padding-top: 30px;
    @include vars.border-top;
    &::after {
        content: "";
        background: url("../../assets/img/map.jpeg");
        background-size: cover;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
}
.wave {
    font-family: vars.$mainFont;
    display: flex;
    flex-direction: column;
    transition: clip-path 1s;
    padding-top: 2%;
    padding-bottom: 2%;
    box-sizing: border-box;
    clip-path: polygon(100% 0%, 0% 0% , 0% 93.50%, 1% 93.48%, 2% 93.41%, 3% 93.29%, 4% 93.14%, 5% 92.93%, 6% 92.69%, 7% 92.40%, 8% 92.07%, 9% 91.70%, 10% 91.30%, 11% 90.86%, 12% 90.39%, 13% 89.89%, 14% 89.36%, 15% 88.81%, 16% 88.23%, 17% 87.64%, 18% 87.03%, 19% 86.41%, 20% 85.78%, 21% 85.14%, 22% 84.49%, 23% 83.85%, 24% 83.21%, 25% 82.58%, 26% 81.96%, 27% 81.35%, 28% 80.75%, 29% 80.18%, 30% 79.63%, 31% 79.10%, 32% 78.60%, 33% 78.13%, 34% 77.69%, 35% 77.29%, 36% 76.92%, 37% 76.60%, 38% 76.31%, 39% 76.06%, 40% 75.86%, 41% 75.70%, 42% 75.59%, 43% 75.52%, 44% 75.50%, 45% 75.52%, 46% 75.59%, 47% 75.71%, 48% 75.87%, 49% 76.07%, 50% 76.32%, 51% 76.61%, 52% 76.94%, 53% 77.30%, 54% 77.71%, 55% 78.15%, 56% 78.62%, 57% 79.12%, 58% 79.65%, 59% 80.20%, 60% 80.78%, 61% 81.37%, 62% 81.98%, 63% 82.60%, 64% 83.24%, 65% 83.87%, 66% 84.52%, 67% 85.16%, 68% 85.80%, 69% 86.43%, 70% 87.05%, 71% 87.66%, 72% 88.26%, 73% 88.83%, 74% 89.38%, 75% 89.91%, 76% 90.41%, 77% 90.88%, 78% 91.31%, 79% 91.72%, 80% 92.08%, 81% 92.41%, 82% 92.70%, 83% 92.94%, 84% 93.14%, 85% 93.30%, 86% 93.41%, 87% 93.48%, 88% 93.50%, 89% 93.48%, 90% 93.41%, 91% 93.29%, 92% 93.13%, 93% 92.92%, 94% 92.68%, 95% 92.39%, 96% 92.06%, 97% 91.69%, 98% 91.29%, 99% 90.85%, 100% 90.37%);
    &__title {
        font-family: vars.$calliFont;
        color: vars.$mainColor;
        font-size: max(35px, 3.5vw);
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__link {
        color: vars.$mainColor;
        margin-top: 10px;
    }

    &--scroll {
        clip-path: polygon(100% 0%, 0% 0% , 0% 70.00%, 1% 70.01%, 2% 70.05%, 3% 70.12%, 4% 70.21%, 5% 70.32%, 6% 70.47%, 7% 70.63%, 8% 70.82%, 9% 71.04%, 10% 71.28%, 11% 71.54%, 12% 71.83%, 13% 72.13%, 14% 72.46%, 15% 72.81%, 16% 73.18%, 17% 73.57%, 18% 73.97%, 19% 74.39%, 20% 74.83%, 21% 75.29%, 22% 75.75%, 23% 76.23%, 24% 76.73%, 25% 77.23%, 26% 77.74%, 27% 78.27%, 28% 78.79%, 29% 79.33%, 30% 79.87%, 31% 80.41%, 32% 80.96%, 33% 81.51%, 34% 82.05%, 35% 82.60%, 36% 83.14%, 37% 83.68%, 38% 84.22%, 39% 84.75%, 40% 85.27%, 41% 85.78%, 42% 86.29%, 43% 86.78%, 44% 87.26%, 45% 87.73%, 46% 88.18%, 47% 88.62%, 48% 89.04%, 49% 89.44%, 50% 89.83%, 51% 90.20%, 52% 90.55%, 53% 90.87%, 54% 91.18%, 55% 91.47%, 56% 91.73%, 57% 91.97%, 58% 92.18%, 59% 92.37%, 60% 92.54%, 61% 92.68%, 62% 92.79%, 63% 92.88%, 64% 92.95%, 65% 92.99%, 66% 93.00%, 67% 92.99%, 68% 92.95%, 69% 92.88%, 70% 92.79%, 71% 92.67%, 72% 92.53%, 73% 92.36%, 74% 92.17%, 75% 91.95%, 76% 91.71%, 77% 91.45%, 78% 91.17%, 79% 90.86%, 80% 90.53%, 81% 90.18%, 82% 89.81%, 83% 89.42%, 84% 89.02%, 85% 88.59%, 86% 88.16%, 87% 87.70%, 88% 87.23%, 89% 86.75%, 90% 86.26%, 91% 85.76%, 92% 85.24%, 93% 84.72%, 94% 84.19%, 95% 83.66%, 96% 83.12%, 97% 82.57%, 98% 82.03%, 99% 81.48%, 100% 80.93%);
    }
}

.image {
    width: 60%;
    
}

.wave1 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/ploumanach.jpeg') no-repeat;
    height: 40vh;
    padding-right: 70%;
    padding-left: 7%;
    background-size: cover;
}

.wave2 {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/trestraou.jpeg') no-repeat;
    height: 40vh;
    padding-left: 70%;
    padding-right: 7%;
    background-size: cover;
}

.wave3 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/lannion.jpeg') no-repeat;
    height: 40vh;
    padding-right: 70%;
    padding-left: 7%;
    background-size: cover;
}

.wave4 {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/trestel.jpeg') no-repeat;
    height: 40vh;
    padding-left: 70%;
    padding-right: 7%;
    background-size: cover;
}

.wave5 {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/renote.jpeg') no-repeat;
    height: 40vh;
    padding-right: 70%;
    padding-left: 7%;
    background-size: cover;
}

.wave6 {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 90%, rgba(255, 255, 255, 1) 90%), url('../../assets/img/proximite/tredrez.jpeg') no-repeat;
    height: 40vh;
    padding-left: 70%;
    padding-right: 7%;
    background-size: cover;
}

@media screen and (max-width: 768px) {
.wave1, .wave2, .wave3, .wave4, .wave5, .wave6 {
    padding: 5%;
}
.wave__content {
    text-shadow: white 0px 0px 10px;
 }
}
