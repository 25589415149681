@use 'src/styles/vars';

.fc {
    width: 70%;
    height: 550px;
    margin: 30px auto;
    //background-color: white;
}
.fc-daygrid {
    background-color: white;
}

.fc-scrollgrid-section-header {
    background-color: vars.$mainColor;
    color: white;
}

.fc-toolbar-title {
    text-transform: capitalize;
    color: vars.$mainColor;
    font-weight: bold;
}

.fc .fc-button-primary {
    background-color: vars.$mainColor;
}