@use 'src/styles/vars';

.contact {
    width: 70%;
    border: 1px solid vars.$mainColor;
    border-radius: 10px;
    box-shadow: 5px 5px 5px vars.$mainColor;
    display: flex;
    &__element {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        font-size: max(15px, 1.5vw);
        & a {
            color: white;
 
        }
    }
    &__left {
        width: 40%;
        background-color: vars.$mainColor;
        color: white;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        padding-top: 2%;
        padding-bottom: 2%;
        &--title {
            margin-bottom: 60px;
            font-size: max(20px, 1.8vw);
        }
    }
    &__right {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__icon {
        width: 32px;
        margin-right: 5%;
        margin-left: 5%;
    }
}

.form {
    width: 90%;
    display: flex;
    flex-direction: column;
    &__label {
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    &__element {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &--name {
            width: 45%;
            // padding-bottom: 20px;
        }
        &--mail {
            width: 45%;
           // padding-bottom: 20px;
        }
    }
    &__input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid vars.$mainColor;
        //margin-bottom: 20px;

    }
    &__button {
        border: 0;
        padding: 15px 20px;
        margin: 15px auto;
        border-radius: 5px;
        color: white;
        background-color: vars.$mainColor;
        box-shadow: 2px 6px 5px vars.$mainColor;
    }
    &__head {
        display: flex;
        width: 100%;
        justify-content: space-between;

    }
}
#message {
    height: 100px;
    margin-bottom: 20px;
}
.recaptcha {
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .contact {
        flex-direction: column;
        width: 96%;
        box-shadow: 0px 5px 5px #27596f;
        &__left {
            width: 100%;
            border-top-right-radius: 9px;
            border-bottom-left-radius: 0px;
        }
        &__right {
            width: 100%;
        }
    }
}