@use 'src/styles/vars';

.contact-page {
    //position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 30px;
    padding-top: 40px;
    margin-bottom: 20px;
    min-height: 83vh;
    @include vars.border-top;
    &::after {
        content: "";
        background: url("../../assets/img/map.jpeg");
        background-size: cover;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
      &__title {
          font-family: vars.$calliFont;
          color: vars.$mainColor;
          font-size: max(50px, 5vw);
          margin-bottom: 20px;
      }
      &__subtitle {
          font-style: italic;
          font-size: max(20px, 2vw);
          margin-top: 30px;
          margin-bottom: 30px;
          overflow: hidden;
         transform: translateY(100%) !important;
         opacity: 0;
        
          
          &--show {
              transform: translateY(0%) !important;
              opacity: 1;
              transition: all 1s ease-in-out;
             
          }
      }
      &__list {
          margin-top: 20px;
          margin-bottom: 50px;
          display: flex;
          width: 30%;
          justify-content: space-evenly;
      }
      &__element {
          display: flex;
          align-items: center;
          & a {
              color: black;
              font-size: 1.5em;
          }

      }
      &__icon {
          margin-right: 20px;
          animation: rotation 4s infinite linear;
          color: red;
      }
}



@keyframes rotation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(359deg);
    }
  }
