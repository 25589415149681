@use 'src/styles/vars';

.gallery {
    background-color: vars.$mainColor;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 40px;
    margin-top: 40px;
    &__image {
        width: 90%;
        transition: transform 1s;
        border-radius: 10px;
        &:hover{
            transform: scale(1.1);
        }
    }
}

@media screen and (max-width: 768px) {
    .gallery {
        flex-wrap: wrap;
        width: 82%;
        justify-content: center;
        margin: 0px auto;
        margin-bottom: 20px;

    }
    .react_lightgallery_item {
        width: 45%;
    }

    .table {
        margin-bottom: 15px;
    }
}
