@use 'src/styles/vars';

.footer {
    background-color: vars.$mainColor;
    min-height: 5vh;
    width: 100%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
/*     position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
}