@use 'src/styles/vars';

.presentation {
    font-family: vars.$mainFont;
    font-size: max(20px, 1.5vw);
    margin-bottom: 20px;
    min-height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @include vars.border-top;

    &::after {
        content: "";
        background: url("../../assets/img/map.jpeg");
        background-size: cover;
        opacity: 0.2;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;   
      }
    &__up {
        display: flex;
        margin-bottom: 30px;
        padding-top: 40px;

    }
    &__left {
        width: 50%;
        padding-left: 5%;
        padding-right: 5%;
    }
    &__right {
        width: 50%;
    }
    &__title {
        color: vars.$mainColor;
        font-family: vars.$calliFont;
        font-size: max(50px, 5vw);
        margin-bottom: 40px;
    }
    &__intro {
        color: vars.$mainColor;
        font-size: max(20px, 1.6vw);
        font-style: italic;
        margin-top: 20px;
    }
    &__content {
        margin-bottom: 50px;
        line-height: 1.2;
    }
}

.table {
    width: 80%;
    margin: 20px auto;
    &__head {
        color: vars.$mainColor;
        font-size: 2rem;
        height: 80px;
    }
    &__td{
       display: flex;
       align-items: center;
       width: 50%;
       height: 85px;

    }
    &__tr {
        display: flex;
    }
    &__img {
        height: 50%;
    }
    & p {
        width: 80%;
        text-align: left;
        padding-left: 15px;
    }
}

@media screen and (max-width: 768px) {
    .presentation {
        &__up {
            flex-direction: column;
            align-items: center;
        }
        &__left {
            width: 95%;
        }
        &__right {
            width: 95%;
        }
    }
}