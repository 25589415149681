// Colors
$mainColor: #27596F;

// Font
$calliFont: 'birds';
$mainFont: 'opensans';

// Border
@mixin border-top {
    border-top: solid 1px #27596F;
  }